<template>
  <ui-flexbox v-bind="{ ...uiFlexboxProps }">
    <ui-text v-bind="{ ...uiTextProps }">
      {{ text }}
    </ui-text>
    <ui-tooltip v-bind="{ ...uiTooltipProps }">
      <ui-icon
        :svg="InfoIcon"
        :size="iconSize"
        tabindex="0"
        filled
        color="textTertiary"
        class="tooltip-icon"
      />
    </ui-tooltip>
  </ui-flexbox>
</template>
<script setup lang="ts">
import InfoIcon from '@base/assets/img/svg/icon-info.svg'
import type { FlexboxProps } from '@base/components/ui/flexbox'
import type { TextProps } from '@base/components/ui/text'
import type { TooltipProps } from '@base/components/ui/tooltip'

type UITooltipProps = Omit<TooltipProps, 'content'>

const props = defineProps({
  text: {
    type: String,
    required: true,
  },
  content: {
    type: String,
    required: true,
  },
  textProps: {
    type: Object as PropType<TextProps>,
    default: () => ({
      variant: 'body3',
      color: 'textSecondary',
    }),
  },
  tooltipProps: {
    type: Object as PropType<UITooltipProps>,
    default: () => ({
      placement: 'top',
      showArrow: false,
    }),
  },
  containerProps: {
    type: Object as PropType<FlexboxProps>,
    default: () => ({
      align: 'center',
    }),
  },
  iconSize: {
    type: String,
    default: '18',
  },
})
const uiTextProps = computed(() => {
  return {
    ...props.textProps,
  } satisfies TextProps
})
const uiTooltipProps = computed(() => {
  return {
    ...props.tooltipProps,
    content: props.content,
  } satisfies TooltipProps
})
const uiFlexboxProps = computed(() => {
  return {
    ...props.containerProps,
  } satisfies FlexboxProps
})
</script>
<style scoped lang="postcss">
.tooltip-icon {
  color: var(--brand-color-text-tertiary);
  &:focus-visible {
    color: var(--brand-color-text-secondary);
    &:deep(circle) {
      stroke: var(--brand-color-text-secondary);
    }
    outline: none;
  }
}
</style>
